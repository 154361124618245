import React from "react"
import styled from "styled-components"
import SyntaxHighlighter from "react-syntax-highlighter"
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { Fonts, Weight } from "../../style/fonts"
import { rem } from "../../util/style"
import he from "he"

const RichTextBlock = styled.div`
  .table-wrap {
    margin-top: ${rem(32)};
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  h6 {
    color: #1343fb;
    font-size: ${rem(16)};
    font-family: ${Fonts.inter};
    font-weight: ${Weight.medium};
    line-height: ${rem(20)};
    letter-spacing: 0.025em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: ${rem(8)};
  }

  h1 {
    color: #06122c;
    font-size: ${rem(45)};
    font-family: ${Fonts.montserrat};
    font-weight: ${Weight.bold};
    line-height: ${rem(55)};
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: ${rem(32)};
  }

  h3 {
    color: #06122c;
    font-size: ${rem(30)};
    font-family: ${Fonts.inter};
    font-weight: ${Weight.bold};
    line-height: ${rem(40)};
    margin-top: 1.5rem;
  }

  p,
  code {
    color: #6b7280;
    font-size: ${rem(18)};
    font-family: ${Fonts.inter};
    font-weight: ${Weight.normal};
    line-height: ${rem(32)};
    margin-bottom: ${rem(24)};

    strong {
      color: #06122c;
      font-weight: ${Weight.medium};
    }

    a {
      color: #4f46e5;
      font-size: ${rem(18)};
      font-family: ${Fonts.inter};
      font-weight: ${Weight.mid};
      line-height: ${rem(32)};
      text-decoration: none;

      position: relative;

      &:after {
        content: " ";
        left: 0;
        right: 0;
        bottom: -3px;
        height: 2px;
        background: #4f46e5;
        position: absolute;
      }
    }
  }

  table {
    border: 1px solid #e6e7ea;
    border-collapse: collapse;

    tbody {
      tr {
        border-top: 1px solid #e6e7ea;
      }
    }
  }

  td,
  th {
    padding: 0px 16px;
    border: none !important;

    color: #6b7280;
    font-size: ${rem(14)};
    font-family: ${Fonts.inter};
    font-weight: ${Weight.normal};
    line-height: ${rem(32)};
  }

  th {
    color: white;
    font-weight: ${Weight.medium};
    background-color: #06122c;
  }

  ol {
    li {
      color: #6b7280;
      font-size: ${rem(18)};
      font-family: ${Fonts.inter};
      font-weight: ${Weight.normal};
      line-height: ${rem(32)};
      margin-bottom: ${rem(12)};
      padding: 0;
      padding-left: ${rem(22)};

      position: relative;
    }
  }
  ul {
    padding: 0;
    padding-left: 4px;
    list-style-type: none;

    li {
      color: #6b7280;
      font-size: ${rem(18)};
      font-family: ${Fonts.inter};
      font-weight: ${Weight.normal};
      line-height: ${rem(32)};
      margin-bottom: ${rem(12)};
      padding: 0;
      padding-left: ${rem(22)};

      position: relative;

      &:before {
        content: " ";
        top: 14px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #6b7280;
        position: absolute;
      }
    }
  }

  blockquote {
    margin: 0;
    margin-top: ${rem(6)};
    margin-bottom: ${rem(30)};
    padding: 0 ${rem(18)};
    position: relative;

    p {
      color: #06122c;
      font-family: ${Fonts.inter};
      font-weight: ${Weight.mid};
      font-style: italic;
      line-height: ${rem(32)};
    }

    &:before {
      content: " ";
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background: #e5e7eb;
      position: absolute;
    }
  }

  pre {
    font-size: ${rem(12)} !important;
  }
`

//data.Content?.data.childMarkdownRemark.html

const BlockRichText = ({ data }) => {
  const splitCodeBlocks = htmlString => {
    // Regular expression to match <code class="language-python"></code> blocks
    const codeBlockRegex = /<code\s+class="(\w+-\w+)">(.*?)<\/code>/gs

    // Array to store the HTML segments
    const htmlSegments = []

    // Keep track of the current index in the input string
    let lastIndex = 0

    // Extract code blocks and other HTML segments
    let match
    while ((match = codeBlockRegex.exec(htmlString)) !== null) {
      const codeBlockStart = match.index
      const codeBlockEnd = codeBlockRegex.lastIndex - match[0].length
      const codeBlockContent = match[2].trim()

      // Add the HTML segment before the code block
      if (codeBlockStart > lastIndex) {
        const beforeCodeBlock = htmlString.slice(lastIndex, codeBlockStart)
        htmlSegments.push(beforeCodeBlock.trim())
      }

      // Add the code block to the array
      htmlSegments.push(
        `<code class="${match[1]}">${codeBlockContent}</code>`
      )

      // Update the last index to continue the search
      lastIndex = codeBlockEnd + match[0].length
    }

    // Add any remaining HTML segment after the last code block
    if (lastIndex < htmlString.length) {
      const afterLastCodeBlock = htmlString.slice(lastIndex)
      htmlSegments.push(afterLastCodeBlock.trim())
    }

    // Filter out any empty strings resulting from the cleanup
    return htmlSegments.filter(segment => segment.length > 0)
  }

  const blocks = splitCodeBlocks(data.Content.data.childMarkdownRemark.html)
  // console.log(blocks)

  // return (
  //   <RichTextBlock>
  //     <ReactMarkdown
  //       children={data.Content.data.Content}
  //       remarkPlugins={[remarkRehype, rehypeStringify]}
  //     />
  //   </RichTextBlock>
  // )

  return (
    <>
      {blocks.map((block, i) => {
        if (block.includes('<code class="language-python">')) {
          return (
            <SyntaxHighlighter language="python" style={docco}>
              {block
                .replace('<code class="language-python">', "")
                .replace("</code>", "")}
            </SyntaxHighlighter>
          )
        }
        else if (block.includes('<code class="language-bash">')){
          const unescaped = he.decode(block
                    .replace('<code class="language-bash">', "")
                    .replace("</code>", ""));
          return(
              <SyntaxHighlighter language="bash" style={docco}>
                {unescaped}
              </SyntaxHighlighter>
          )
        }
        else if (block.includes('<code class="language-vim">')){
          return(
              <SyntaxHighlighter language="vim" style={docco}>
                {block
                    .replace('<code class="language-vim">', "")
                    .replace("</code>", "")
                }
              </SyntaxHighlighter>
          )
        }
        else if (block.includes('<code class="language-go">')){
          return(
              <SyntaxHighlighter language="go" style={docco}>
                {block
                    .replace('<code class="language-vim">', "")
                    .replace("</code>", "")
                }
              </SyntaxHighlighter>
          )
        }

        // else
        return (
          <RichTextBlock
            dangerouslySetInnerHTML={{
              __html: block,
            }}
          />
        )
      })}
    </>
  )
}

export default BlockRichText
